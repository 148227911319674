import React from "react"
import { graphql } from "gatsby"
import CustomMarkdown from "../utils/customMarkdown"
import "../scss/secondary-page.scss"
import 'uikit/dist/css/uikit.css'



import SEO from "../components/seo"
import Layout from "../components/layout"
import useSiteMetadata from "../utils/useSiteMetadata"
import { removeSlashFromSlug } from '../utils/additionalFunctions';



import DefaultImag from "../assets/img/default_pic.jpg"
// import VideoComponent from "../components/video-component"

import loadable from '@loadable/component'

const SubheadingText = loadable(() => import("../components/basic-components/subheading-text"))
const VideoComponent = loadable(() => import("../components/video-component"))
const Tiles = loadable(() => import("../components/tiles"))
// const QuoteCarousel = loadable(() => import("../components/quote-carousel"))

export const query = graphql`
  query LandingPagesQuery($id: Int) {
    strapiLandingPages(strapiId: {eq: $id}) {
        SEOTitle
        SEODescription
        Canonical
        MetaTags{
          MetaName
          MetaContent
        }
        Title
        slug
        strapiId
        Video {
      YoutubeURL
    }
        CTA {
          CTA {
            Background {
              localFile{
                id
                publicURL
                childImageSharp{
                  fluid(quality: 90, maxWidth: 1900) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              
            }
            Title
            url
          }
        }
        CTAFullwidth {
          Background {
            localFile{
              id
              publicURL
              childImageSharp{
                fluid(quality: 90, maxWidth: 1900) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            
          }
          Title
          url
        }
        Header {
          CTA
          Subtitle
          URL
          LeftBgColor
          RightBgColor
          Background {
            localFile{
              publicURL
              childImageSharp{
                fluid(quality: 90, maxWidth: 1900) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            
          }
          Title
          Visible
          Subheading
          SubheadingSummary
        }
        TextSection {
          Text
        }
    }
  }
`






const LandingPage = ({data}) => {


  var headerData={
    headerTitle: data.strapiLandingPages.Header.Title, 
    headerDescription: data.strapiLandingPages.Header.Subtitle, 
    headerCTAPrimaryLabel: data.strapiLandingPages.Header.CTA, 
    headerCTAPrimaryUrl: data.strapiLandingPages.Header.URL, 
    headerCTASecondaryLabel: data.strapiLandingPages.Header.CTASecondary, 
    headerCTASecondaryUrl: data.strapiLandingPages.Header.URLSecondary,
    headerBgImage: data.strapiLandingPages.Header.Background?data.strapiLandingPages.Header.Background.localFile.childImageSharp.fluid:DefaultImag,
    headerRightBgColor: data.strapiLandingPages.Header.RightBgColor ? data.strapiLandingPages.Header.RightBgColor : '',
    headerLeftBgColor: data.strapiLandingPages.Header.LeftBgColor ? data.strapiLandingPages.Header.LeftBgColor : '',
  };


  if(data.strapiLandingPages.CTAFullwidth){
    var fullwidthCTA = [{
      Background: {
        publicURL: (data.strapiLandingPages.CTAFullwidth.Background?data.strapiLandingPages.CTAFullwidth.Background.localFile.childImageSharp.fluid:DefaultImag),
        childImageSharp: (data.strapiLandingPages.CTAFullwidth.Background?data.strapiLandingPages.CTAFullwidth.Background.localFile.childImageSharp:DefaultImag),
        // localFile___NODE: data.strapiLandingPages.CTAFullwidth.Background.localFile.id
        localFile___NODE: (data.strapiLandingPages.CTAFullwidth.Background?data.strapiLandingPages.CTAFullwidth.Background.localFile.id:"cb71e5db-db3c-5a8c-a66a-3da1c7f4668b") 
      },
      Title: data.strapiLandingPages.CTAFullwidth.Title,
      url: data.strapiLandingPages.CTAFullwidth.url,
    }]
    // console.log("fullwidthCTA", data.strapiLandingPages.CTAFullwidth.Background)
  }
  
  
  const { siteUrl: url } = useSiteMetadata();
  


return(

  <div>
    
    <Layout pageData={headerData} headerShow={data.strapiLandingPages.Header.Visible} headerType="contact">
      <SEO title={data.strapiLandingPages.SEOTitle?data.strapiLandingPages.SEOTitle:data.strapiLandingPages.Title} 
      description={data.strapiLandingPages.SEODescription} 
      meta={data.strapiLandingPages.MetaTags}
      canonical={data.strapiLandingPages.Canonical ? data.strapiLandingPages.Canonical : `${url + '/' + removeSlashFromSlug(data.strapiLandingPages.slug)}`}
      />


    
      {/* <SubheadingText text={"Consequat est dolor nisi nulla sunt tempor proident consectetur enim."}/> */}
      {data.strapiLandingPages.Header.Subheading?
      <SubheadingText subheading={data.strapiLandingPages.Header.Subheading} summary={data.strapiLandingPages.Header.SubheadingSummary}/>:""
      }
      

      <Tiles data={data.strapiLandingPages.CTA.CTA}/>
      {/* {console.log(data.strapiLandingPages.CTA.CTA)} */}


      {fullwidthCTA?
      <div className="padding-bottom">

        <Tiles data={fullwidthCTA} singleFlag={true} width='full'/></div>
        :""
      
      }

      {/* <div className="secondary-page"> */}

        

        {/* {data.strapiLandingPages.PageComponents.map((item, index) => {
          return(
            <>
              {(item.Text)?<CustomMarkdown children={item.Text} />:""}

              {(item.Testimonial)?<QuoteCarousel quoteList={item.Testimonial}></QuoteCarousel>:""}

              {(item.Images)?<div className="image-section uk-height-medium uk-cover-container uk-margin-none"><img src={item.Images.publicURL} alt="" data-uk-cover/></div>:""}
            
             
            </>
          )
        })} */}
        
      {/* </div> */}

{data.strapiLandingPages.TextSection?.Text ? <div className="video-title lateral-padding padding-bottom">
   
         <CustomMarkdown children={data.strapiLandingPages.TextSection.Text} /> 
</div>: ''}
      

         {data.strapiLandingPages.Video ? (<div className="padding-bottom">

                  <VideoComponent  src={data.strapiLandingPages.Video.YoutubeURL}/>
         </div>
                ) : ('')
             }

    </Layout>
    
  </div>
)}

export default LandingPage
